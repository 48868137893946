/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { Fragment } from 'react';
import { Row, Link, Hero, Image } from 'components/lib';
import Rive from '@rive-app/react-canvas';
import ImgCode from './images/blue-code.png';
import ImgData from './images/data-abstract.png';
import Devices from './images/devices.png';
import UncloakRiv from './riv/uncloak_shapes.riv';

import Style from './website.module.scss';

export function Home(props){

  return(
    <Fragment>

      <div className={Style.backdrop}>
        <div className={Style.overlay}></div>

        <Rive 
          src={ UncloakRiv }
          className={Style.rive}
        />

<div className={ Style.deviceImage }>
          <Image source={ Devices } alt={ props.alt } />
        </div>
      </div>
        
      <Hero
        title='Develop Custom Software for Natural Commodities'
        tagline={
          'Providing data transparency for businesses within the natural resource industry. Specialize in visual data and the management of data access.'
        }
        contentCenter
        className={Style.mainSection}
      />

      <Hero
        className={Style.detailSectionOne}
        contentRight
        title='Who We Are'
        tagline='Uncloak Data Labs is a company that specializes in energy application software development.  The company leadership consists of over two decades of robust experience in the energy and software development industries.  This broad wealth of knowledge has led to the creation of an application that allows for transparency in all aspects of commodities business processes.'
        image={ ImgCode }
      />

      <Row color='dark'>
        <Hero
          title='What We Do'
          tagline='We develop software that provides transparency that will enable companies to have a more streamlined nomination and trading process through the development of applications that centralize all nomination and trading flows. The application will allow your company to generate reports that will show nominations and trades that are made daily. This transparency reporting eliminates duplicative manual effort and increases productivity'
          image={ ImgData }
        />
      </Row>

      <Row title='Contact Us to Kickstart Your Project Today' align='center'>
        <Link btn url='/contact' text='Contact Us Now'/>
      </Row>
    </Fragment>
  );
}
