/***
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import React from 'react';
import { Animate, Row, Content, Link } from 'components/lib'
import Style from './footer.module.scss';

export function Footer(props){

  return (
    <Animate type='slideup'>
      <footer className={ Style.footer }>
        <Row>
          <Content>

            <span>{ `Copyright © Uncloak Data Labs ${new Date().getFullYear()}`}</span>
            <address>101 Deerwood Park BLVD bldg 200 Ste 250 Jacksonville, FL 32256</address>

          </Content>
        </Row>
      </footer>
    </Animate>
  );
}
