/***
*
*   HERO
*   Hero section on landing page
*
*   PROPS
*   title: h1 title
*   tagline: h2 tagline
*   image: source object
*   alt: img alt tag
*
**********/

import React from 'react';
import { Animate, Content, Image } from 'components/lib';
import ClassNames from 'classnames';
import Style from './hero.module.scss';

export function Hero(props){

  const css = ClassNames([

    Style.hero,
    props.className

  ]);

  let contentStyle = [Style.blurb];
  let imageStyle = [Style.visual];

  if (props.contentRight) {
    contentStyle = [...contentStyle, Style.floatRight, Style.contentWidth];
    imageStyle = [...imageStyle, Style.floatLeft, Style.imageWidth];
  } else if (props.contentCenter) {
    contentStyle.push(Style.textCenter);
  } else {
    contentStyle = [...contentStyle, Style.floatLeft, Style.contentWidth];
    imageStyle = [...imageStyle, Style.floatRight, Style.imageWidth];
  }

  return (
    <section className={ css }>
      <Content>

        <div className={ Style.contentSection }>
          <Animate type='pop'>
            <section className={ ClassNames(contentStyle) }>
              <h1>{ props.title }</h1>
              <h2>{ props.tagline }</h2>
            </section>
          </Animate>

          {props.image &&
            <Animate>
              <div className={ ClassNames(imageStyle) }>
                <Image source={ props.image } alt={ props.alt } />
              </div>
            </Animate>
          }
        </div>

      </Content>
    </section>
  )
}
