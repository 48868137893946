import React from 'react';
import Axios from 'axios';

// components
import { Router, Route, Switch } from 'react-router-dom';
import { History, View } from 'components/lib';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// 404
import { NotFound } from 'views/error/404';

// settings
const Settings = require('settings.json');
const env = process.env.REACT_APP_ENV || process.env.NODE_ENV
const StripePromise = loadStripe(Settings[process.env.NODE_ENV].stripe.publishableAPIKey);

const Routes = [
  ...require('routes/website').default
]

export default function App(props){

  const user = JSON.parse(localStorage.getItem('user'));
  Axios.defaults.baseURL = Settings[env].server_url

  if (user?.token){

    // add auth token to api header calls
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;

  }

  // render the routes
  return(
    <Elements stripe={ StripePromise }>
      <Router history={ History }>
          <Switch>
            { Routes.map(route => {

              return (
                <Route key={ route.path } exact path={ route.path }
                  permission={ route.permission } render={(data) =>
                  <View display={ route.view } layout={ route.layout }
                    title={ route.title } data={ data }
                  />}
                />
              );
            })}

            { /* 404 */}
            <Route render={() => <View display={ NotFound }
              layout='home' title='404 Not Found' />}/>

          </Switch>
        </Router>
    </Elements>
  );
}
