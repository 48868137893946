/***
*
*   HOME NAV
*   Navigation used on the main external website. Renders a dashboard link
*   if the user is signed in, or a sign up link if they are not
*
**********/

import React from 'react';
import { useLocation } from "react-router-dom";
import ClassNames from 'classnames';
import { Logo, Content } from 'components/lib';
import Style from './home.module.scss';

export function HomeNav(props){
  const location = useLocation();
  
  // style
  const css = ClassNames([

    Style.nav,
    props.transparent && Style.transparent

  ]);

  return(
    <section className={ css }>
      <Content>

        <Logo className={ Style.logo }/>

      </Content>
    </section>
  );
}
